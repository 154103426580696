import React, { useContext }  from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Link from '@utility/Link'
import LocaleContext from '@context/LocaleContext'
import InViewport from '@components/InViewport'
import TitleVertical from '@components/TitleVertical'
import RichText from '@components/RichText'
import styles from './styles'

const LatestNews = ({
  data: { latestNewsImage, latestNewsDescription, latestNewsLink }
}) => {
  const { code } = useContext(LocaleContext)

  return (
    <InViewport
      className={styles.container}
      threshold={0.4}
    >
      <div className="wrapper">
        <div className="row">
          <div className="col-12 sm:col-5 md:offset-1">
            <Img
              fluid={latestNewsImage.asset.fluid}
              className={styles.img}
              alt="Latest News Image"
              placeholderClassName="gatsby-image-placeholder"
            />
          </div>
          <div className="col-10 sm:col-5 sm:offset-1 md:col-4 lg:col-3 lg:offset-2 sm:flex sm:items-center">
            <div>
              <TitleVertical
                title={{
                  jp: "最新",
                  en: "Latest"
                }}
                index={3}
                className="mb-8"
              />
              <div className={styles.content}>
                <RichText
                  localeBlocks={latestNewsDescription}
                  lang={code}
                />
                {latestNewsLink && latestNewsLink[0] && (
                  <Link
                    to={latestNewsLink[0]}
                    internalExternal
                    styled
                    className="mt-14"
                  >
                    {code === 'jp' ? latestNewsLink[0].label.jp : latestNewsLink[0].label.en }
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </InViewport>
  )
}

LatestNews.propTypes = {
  data: PropTypes.shape({
    latestNewsImage: PropTypes.object,
    latestNewsDescription: PropTypes.object,
    latestNewsLink: PropTypes.arrayOf(PropTypes.object),
  }).isRequired
}

export default LatestNews
